import Header from "../../components/Header/Header";
import Overview from "../../components/Overview";
import Activities from "../../components/Activities/ActivitiesComponent";
import { ICurrentUser } from "../../types/Types";
import { useSelector } from "react-redux";
import { useGetAnalyticsQuery } from "../../services/dashboardAnalysis/dashboardService";

const Home = () => {
  const staffInfoJSON = localStorage.getItem("staffInfo");
  const localInfo = staffInfoJSON && JSON.parse(staffInfoJSON);
  let role = localInfo?.user?.role;
  let staffId = localInfo?.user?.staffId;
  let username = localInfo?.user?.displayName;

  const modifiedFullName: string = username?.replace(/\s+/g, ".");
  const getAnalytics = useGetAnalyticsQuery({ modifiedFullName });

  const dashboardData = getAnalytics?.data?.data;

  return (
    <>
      <Header title="Dashboard" />
      <Overview displayName={username} role={role} staffId={staffId} />
      <Activities dashboardAnalysis={dashboardData} />
    </>
  );
};

export default Home;

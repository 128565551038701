import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { UserDetails } from "../../types/Types";
import { fetchPublicIP } from "../../utils/config";
import { generateHeader } from "../../utils/headers";
let publicIP: string | null = null;
fetchPublicIP().then((ip) => {
  publicIP = ip;
});

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface ChargeResponse {
  id: number;
  chargeAmount: number;
  minimumTransactionAmount: number;
  maximumTransactionAmount: number;
  fcmbIncome: number;
  nibssIncome: number;
  vat: number;
  createdOn: string;
  lastModified: Date;
  createdBy: string;
}

interface AddResponse {
  data: string;
  error: string;
  responseCode: string;
  responseDescription: string;
}
interface UpdateChargeData {
  data: ChargeResponse;
  responseCode: string;
  responseDescription: string;
  error: string;
}

export interface ChargeStruct {
  [key: string]: number;
  chargeAmount: number;
  minimumTransactionAmount: number;
  maximumTransactionAmount: number;
  fcmbIncome: number;
  nibssIncome: number;
  vat: number;
}

interface ChargeStructRequest {
  body?: ChargeStruct;
  chargeId?: number;
}

interface ChargeStructResponse {
  data?: ChargeStruct;
}

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;

      headers.set("Content-Type", "application/json");
      headers.set("accept", "text/plain");

      if (publicIP) {
        headers.set("remote-address", publicIP);
      }
      Object.entries(generateHeader()).forEach(([key, value]) => {
        if (value !== undefined) {
          headers.set(key, String(value));
        }
      });
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    //get a charge
    getAnalytics: builder.query<any, { modifiedFullName: string }>({
      query: ({ modifiedFullName }) => ({
        url: `/dashboard/analytics?userName=${modifiedFullName}`,
        method: "GET",
      }),
    }),

    //getdetails
    getUserDetails: builder.mutation<UserDetails, { username?: string }>({
      query: ({ username }) => ({
        url: `user/get-user-details?username=${username}`,
        method: "GET",
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    logout: builder.mutation<any, void>({
      query: () => ({
        url: "/user/logout",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useGetAnalyticsQuery,
  useGetUserDetailsMutation,
  useLogoutMutation,
} = dashboardApi;

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import {
  ChequePayload,
  ChequeResponse,
  MandateResponse,
  PostWithdrawal,
  ViewMandatePayload,
  WithdrawalRes,
} from "./types";
import { fetchPublicIP } from "../../utils/config";
import { generateHeader } from "../../utils/headers";
let publicIP: string | null = null;
fetchPublicIP().then((ip) => {
  publicIP = ip;
});
const BASE_URL = process.env.REACT_APP_BASE_URL;

export interface Welcome {
  statusCode?: number;
  message?: string;
  data?: Data[];
}

export interface Data {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  schemeCode?: string;
  currency?: string;
  status?: string;
  accountType?: string;
  mobile?: number;
  email?: string;
  availableBalance?: number;
  beneficiaryName?: string;
  ledgerBalance?: number;
  customerType?: string;
  openingDate?: Date;
  accountNumberType?: string;
  bvn?: string;
  tin?: null;
  nin?: string;
  restriction?: null;
  address?: string;
  brokerCode?: string;
  accountOfficer?: string;
  accountNumber?: string;
  customerId?: string;
  limitResponse?: {
    dailyLimit?: number;
    weeklyLimit?: number;
  };
}

export interface IntraBankDeposit {
  isOwner: boolean;
  currency: string;
  instrumentType?: string;
  chequeRefNo?: string;
  narration: string;
  phoneNumber: string;
  benefactorAccountNumber: string;
  beneficiaryAccountNumber: string;
  amount: number;
  remark?: string;
  instrumentDate: string;
}

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;
      headers.set("Content-Type", "application/json");
      headers.set("accept", "text/plain");
      headers.set("Authorization", `Bearer ${token}`);
      headers.set("Access-Control-Allow-Origin", "*");
      if (publicIP) {
        headers.set("remote-address", publicIP);
      }
      Object.entries(generateHeader()).forEach(([key, value]) => {
        if (value !== undefined) {
          headers.set(key, String(value));
        }
      });
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const transactionApi = createApi({
  reducerPath: "transactionApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    postDeposit: builder.mutation<any, any>({
      query: (body) => ({
        url: `/transaction/deposit`,
        method: "POST",
        body,
      }),
    }),

    //fetchAccount
    getAccDetails: builder.mutation<Data, { accountNo?: string }>({
      query: ({ accountNo }) => ({
        url: `/transaction/accountbynumber?accountNumber=${accountNo}`,
        method: "GET",
      }),
      transformResponse: (response: { data: Data }, meta, arg) => response.data,
    }),
    //post withdrawal
    postWithdrawal: builder.mutation<WithdrawalRes, PostWithdrawal>({
      query: (body) => ({
        url: "/transaction/withdraw",
        method: "POST",
        body,
      }),
    }),

    //fcmb transfer
    postIntraBank: builder.mutation<any, IntraBankDeposit>({
      query: (body) => ({
        url: `/transaction/fcmbtransfer`,
        method: "POST",
        body,
      }),
    }),

    // get banks
    getBanks: builder.query<any, void>({
      query: () => ({
        url: `/transaction/banks`,
        method: "GET",
      }),
    }),
    // get interbank enquiry
    getInterBankAccountDetails: builder.query<
      any,
      { bankCode: string | number; accountNumber: string | number }
    >({
      query: ({ bankCode, accountNumber }) => ({
        url: `/transaction/interbankaccountenquiry?DestinationInstitutionCode=${bankCode}&AccountNumber=${accountNumber}`,
        method: "GET",
      }),
    }),

    postInterBank: builder.mutation<any, any>({
      query: (body) => ({
        url: `/transaction/interbanktransfer`,
        method: "POST",
        body,
      }),
    }),

    getReceipt: builder.mutation<
      any,
      { transactionType: string; transactionId: string | undefined }
    >({
      query: ({ transactionType, transactionId }) => ({
        url: `/transaction/get-receipt?transactionType=${transactionType}&transactionId=${transactionId}`,
        method: "GET",
      }),
    }),

    // view mandate
    viewMandate: builder.mutation<MandateResponse, any>({
      query: (body) => ({
        url: `/transaction/view-mandate`,
        method: "POST",
        body,
      }),
    }),

    // view mandate
    validateCheque: builder.mutation<ChequeResponse, ChequePayload>({
      query: (body) => ({
        url: `/transaction/cheque-inquiry`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetAccDetailsMutation,
  usePostInterBankMutation,
  useGetBanksQuery,
  usePostWithdrawalMutation,
  useGetReceiptMutation,
  usePostIntraBankMutation,
  useGetInterBankAccountDetailsQuery,
  usePostDepositMutation,
  useViewMandateMutation,
  useValidateChequeMutation,
} = transactionApi;

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import { number } from "yargs";
import { fetchPublicIP } from "../../utils/config";
import { generateHeader } from "../../utils/headers";
let publicIP: string | null = null;
fetchPublicIP().then((ip) => {
  publicIP = ip;
});

const BASE_URL = process.env.REACT_APP_BASE_URL;

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;
      headers.set("Content-Type", "application/json");
      headers.set("accept", "text/plain");
      headers.set("Access-Control-Allow-Origin", "*");
      if (publicIP) {
        headers.set("remote-address", publicIP);
      }
      
      Object.entries(generateHeader()).forEach(([key, value]) => {
        if (value !== undefined) {
          headers.set(key, String(value));
        }
      });
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    //get a role
    getRoles: builder.query<any, void>({
      query: () => ({
        url: `/admin/roles`,
        method: "GET",
      }),
    }),

    //add a user
    addUser: builder.mutation<any, any>({
      query: (body) => ({
        url: `/admin/add-user`,
        method: "POST",
        body,
      }),
    }),
    //delete a role

    deleteRole: builder.mutation<any, any>({
      query: (roleId) => ({
        url: `/admin/delete?roleId=${roleId}`,
        method: "DELETE",
      }),
    }),

    // add a role

    addRole: builder.mutation<any, any>({
      query: (body) => ({
        url: `/admin/add-role`,
        method: "POST",
        body,
      }),
    }),
    //get all users
    getAllUsers: builder.query<any, { pageNumber: number }>({
      query: ({ pageNumber }) => ({
        //url: `/admin/users`,
        url: `/admin/users?pageNumber=${pageNumber}&pageSize=10`,
        method: "GET",
      }),
    }),

    // update user status

    changeStatus: builder.mutation<any, { username: string }>({
      query: ({ username }) => ({
        url: `/admin/updateuserstatus?username=${username}`,
        method: "PATCH",
      }),
    }),

    updateUserRole: builder.mutation<any, any>({
      query: (body) => ({
        url: `/admin/update-user`,
        method: "PATCH",
        body,
      }),
    }),

    getRole: builder.query<any, { roleId: number | undefined }>({
      query: ({ roleId }) => ({
        url: `/admin/role?roleId=${roleId}`,
        method: "GET",
      }),
    }),

    getUsersInRole: builder.query<any, { roleId: number | undefined }>({
      query: ({ roleId }) => ({
        url: `/admin/role-users?roleId=${roleId}`,
        method: "GET",
      }),
    }),

    getUser: builder.mutation<any, { username: string }>({
      query: ({ username }) => ({
        url: `/admin/user?username=${username}`,
        method: "GET",
      }),
    }),

    updateRole: builder.mutation<any, any>({
      query: (body) => ({
        url: `/admin/update-role`,
        method: "PATCH",
        body,
      }),
    }),

    getAdminRoles: builder.query<any, void>({
      query: () => ({
        url: `/admin/roles`,
        method: "GET",
      }),
    }),

    approveRoles: builder.mutation<any, { roleId: number }>({
      query: ({ roleId }) => ({
        url: `/admin/approverole?roleApprovalId=${roleId}&approve=true`,
        method: "POST",
      }),
    }),

    declineRoles: builder.mutation<any, { roleId: number }>({
      query: ({ roleId }) => ({
        url: `/admin/approverole?roleApprovalId=${roleId}&approve=false`,
        method: "POST",
      }),
    }),

    getPendingRoles: builder.query<any, void>({
      query: () => ({
        url: `/admin/pendingroleapprovals`,
        method: "GET",
      }),
    }),

    //get pending user
    pendingUserApproval: builder.mutation<any, void>({
      query: (token) => ({
        url: "admin/pendingroleapprovals",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    //get pending role
    pendingRoleApproval: builder.mutation<any, void>({
      query: (token) => ({
        url: "admin/users-pending-approval",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    //approve role
    approveRole: builder.mutation<
      any,
      { roleApprovalId: number; approve: boolean }
    >({
      query: ({ roleApprovalId, approve }) => ({
        url: `admin/approverole?roleApprovalId=${roleApprovalId}&approve=${approve}`,
        method: "POST",
      }),
    }),
    //approve user
    approveUser: builder.mutation<
      any,
      { username: string; isApproved: boolean }
    >({
      query: (body) => ({
        url: `admin/approval-user`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetRolesQuery,
  useGetAllUsersQuery,
  useGetRoleQuery,
  useUpdateUserRoleMutation,
  useAddRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useGetUsersInRoleQuery,
  useGetPendingRolesQuery,
  useChangeStatusMutation,
  useGetUserMutation,
  useGetAdminRolesQuery,
  useDeclineRolesMutation,
  useAddUserMutation,
  useApproveRolesMutation,
  usePendingUserApprovalMutation,
  useApproveRoleMutation,
  useApproveUserMutation,
  usePendingRoleApprovalMutation,
} = adminApi;

import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store/store";
import {
  CollectionResponse,
  CollectionPayload,
  PostBUAPayload,
  ApproveBUAPayload,
  ValidateDangote,
  PaymentAction,
  TransactionRequest,
} from "./type";
import { fetchPublicIP } from "../../utils/config";
import { generateHeader } from "../../utils/headers";

let publicIP: string | null = null;
fetchPublicIP().then((ip) => {
  publicIP = ip;
});

const BASE_URL = process.env.REACT_APP_BASE_URL;

const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseResult = await fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).StaffDataReducer?.staffInfo
        ?.accessToken;

      headers.set("Content-Type", "application/json");
      headers.set("accept", "text/plain");
      if (publicIP) {
        headers.set("remote-address", publicIP);
      }
      Object.entries(generateHeader()).forEach(([key, value]) => {
        if (value !== undefined) {
          headers.set(key, String(value));
        }
      });
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

    },
  })(args, api, extraOptions);

  const newResponse: any = {
    ...baseResult,
  };
  const errorCode = newResponse?.data?.statusCode;
  if (errorCode === 401) {
    localStorage.clear();
    window.location.href = "/login";
  }
  return baseResult;
};

export const collectionApi = createApi({
  reducerPath: "collectionApi",
  baseQuery: customBaseQuery,

  endpoints: (builder) => ({
    //get collection list
    getCollectionList: builder.mutation<CollectionResponse, CollectionPayload>({
      query: ({ collectionType }) => ({
        url: `utilities/collections/${collectionType}`,
        method: "POST",
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    //validateBua
    validateId: builder.mutation<any, { orderid: string; category: string }>({
      query: (body) => ({
        url: "bua/validate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          category: body.category, // Set the password in the header
        },
        body,
      }),

      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    //postBUA
    postBuaPayment: builder.mutation<
      CollectionResponse,
      { body: PostBUAPayload; category: string }
    >({
      query: ({ body, category }) => ({
        url: "bua/payment",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          category: category, // Set the password in the header
        },
        body,
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    //approvePayment
    approveBua: builder.mutation<CollectionResponse, ApproveBUAPayload>({
      query: (body) => ({
        url: "bua/approve-payment",
        method: "POST",
        body,
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    //approvePayment
    validateCustomer: builder.mutation<any, ValidateDangote>({
      query: ({ category, customerNumber }) => ({
        url: `dangote/validate-customer/${category}/${customerNumber}`,
        method: "POST",
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    postDangote: builder.mutation<any, TransactionRequest>({
      query: (body) => ({
        url: "dangote/post-payment",
        method: "POST",
        body,
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    approveDecline: builder.mutation<any, PaymentAction>({
      query: (body) => ({
        url: "dangote/approve-decline-payment",
        method: "POST",
        body,
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    pendingPayment: builder.mutation<any, void>({
      query: (body) => ({
        url: "dangote/pending-payment",
        method: "GET",
        body,
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    collectionReport: builder.mutation<
      any,
      {
        TransactionType: string;
        StartDate: string;
        EndDate: string;
        collectionName: string;
      }
    >({
      query: ({ TransactionType, StartDate, EndDate, collectionName }) => ({
        url: `report/collection-report?TransactionType=${TransactionType}&StartDate=${StartDate}&EndDate=${EndDate}&collectionName=${collectionName}`,
        method: "GET",
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),

    buaPendingPayment: builder.mutation<
      any,
      { startDate?: string; endDate?: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `bua/pending?startDate=${startDate}&endDate=${endDate}`,
        method: "GET",
      }),
      // transformResponse: (response: { data: Data[] }, meta, arg) =>
      //   response.data,
    }),
  }),
});

export const {
  useGetCollectionListMutation,
  useValidateIdMutation,
  usePostBuaPaymentMutation,
  useApproveBuaMutation,
  useValidateCustomerMutation,
  usePostDangoteMutation,
  useApproveDeclineMutation,
  usePendingPaymentMutation,
  useCollectionReportMutation,
  useBuaPendingPaymentMutation,
} = collectionApi;

import { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import {
  Container,
  HeaderText,
  BottomContainer,
  TableContainer,
  ContentLayout,
} from "./style";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PrimaryTable from "../../../components/Table";
import PrimaryButton from "../../../components/Buttons/Button";
import PuffLoader from "../../../components/Loader";
import {
  useApproveUserMutation,
  usePendingRoleApprovalMutation,
} from "../../../services/admin/adminService";
import { ErrorHandler } from "../../../utils/config";

const UserApproval = () => {
  const token = useSelector(
    (state: any) => state.StaffDataReducer?.staffInfo?.accessToken
  );
  const [pendingList, setpendingList] = useState<any>([]);
  const [username, setUsername] = useState<string>("");
  const [pendingUser, pendingUserMutation] = usePendingRoleApprovalMutation();
  const [approveUser, approveUserMutation] = useApproveUserMutation();

  const handleGetPendingList = async () => {
    try {
      const response = await pendingUser().unwrap();
      if (response.statusCode === 200) {
        setpendingList(response?.data);
      }
    } catch (err: any) {
      toast.error(err?.data?.responseDescription);
    }
  };
  useEffect(() => {
    handleGetPendingList();
  }, []);

  const handleAction = async (username: string) => {
    setUsername(username);
  };

  const handleApprove = async () => {
    try {
      const requiredData = {
        username: username,
        isApproved: true,
      };

      const response = await approveUser(requiredData).unwrap();
      if (response.statusCode === 200) {
        toast.success(response?.message);
        handleGetPendingList();
      }
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  const handleDecline = async () => {
    try {
      const requiredData = {
        username: username,
        isApproved: false,
      };
      const response = await approveUser(requiredData).unwrap();

      if (response.statusCode === 200) {
        toast.success(response?.message);
        handleGetPendingList();
      }
    } catch (err: any) {
      ErrorHandler(err);
    }
  };

  const userActionList = [
    {
      title: "Approve",
      action: handleApprove,
    },
    {
      title: "Decline",
      action: handleDecline,
    },
  ];

  const header: any = [
    {
      id: 1,
      title: "S/N",
    },
    {
      id: 2,
      title: "Role Name",
    },

    {
      id: 3,
      title: "Role Description",
    },
    {
      id: 4,
      title: "Created By",
    },
    {
      id: 5,
      title: "Created On",
    },
    {
      id: 6,
      title: "Action",
    },
  ];

  const dataBody = pendingList?.map((item: any, index: number) => [
    index + 1,
    item?.username,
    item?.Role,
    item?.createdby,
    item?.DateCreated?.slice(0, 10),
    <>
      <PrimaryButton
        key={item.username}
        background={"#fff"}
        color={"#5c2684"}
        width={"100%"}
        title={"Action"}
        icon={""}
        rightIcon={true}
        loading={false}
        leftIconName={<></>}
        rightIconName={<></>}
        onClick={() => handleAction(item?.username)}
        leftIcon={false}
        border={"1px solid #5c2684"}
        action={true}
        options={userActionList}
        item={item}
      />
    </>,
  ]);

  return (
    <>
      <Header title="Profile Management" />
      <ContentLayout>
        <Container>
          <BottomContainer>
            <HeaderText>Users</HeaderText>
          </BottomContainer>

          <TableContainer>
            {pendingUserMutation?.isLoading ? (
              <PuffLoader />
            ) : (
              <PrimaryTable
                header={header}
                body={dataBody}
                arrOfObject={true}
                itemsPerPage={10}
              />
            )}
          </TableContainer>
        </Container>
      </ContentLayout>
    </>
  );
};

export default UserApproval;

import {
  SidebarButtonType,
  TableProps,
  cardComponentType,
  ListProps,
  DropDownOptions,
  HeaderData,
  DeProp,
  CustomerInfoProp,
  BuaInfoProp,
  CollectionInfoProp,
} from "../types/Types";

import { ReactComponent as HomeIcon } from "../assets/svg/home.svg";
import { ReactComponent as Home2Icon } from "../assets/svg/home2.svg";
import { ReactComponent as TransactIcon } from "../assets/svg/transact.svg";
import { ReactComponent as Transact2Icon } from "../assets/svg/transact2.svg";
import { ReactComponent as Profile } from "../assets/svg/profile.svg";
import { ReactComponent as Profile2 } from "../assets/svg/profile-circle.svg";
import { ReactComponent as ClearingIcon } from "../assets/svg/clearing.svg";
import { ReactComponent as ClearingActive } from "../assets/svg/clearingactive.svg";
import { ReactComponent as Cloud2 } from "../assets/svg/cloud-lightning.svg";
import { ReactComponent as Cloud } from "../assets/svg/cloud.svg";
import { ReactComponent as Exemption } from "../assets/svg/exemption.svg";
import { ReactComponent as Approve } from "../assets/svg/approve.svg";
import { ReactComponent as Inward } from "../assets/svg/inward.svg";
import { ReactComponent as Outward } from "../assets/svg/outward.svg";
import { ReactComponent as Finacle } from "../assets/svg/finacle.svg";
import { ReactComponent as Bill } from "../assets/svg/bill.svg";

import usa from "../assets/images/usa.png";
import nga from "../assets/images/nga.png";
import uk from "../assets/images/uk.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import toast from "react-hot-toast";

export const adminSidebarButtons: SidebarButtonType[] = [
  {
    id: 1,
    title: "Home",
    path: "/dashboard",
    iconActive: HomeIcon,
    icon: Home2Icon
  },
  {
    id: 2,
    title: "TSA Remittance",
    path: "/dashboard/remittance",
    iconActive: TransactIcon,
    icon: Transact2Icon,
  },
  {
    id: 3,
    title: "Deposits",
    path: "/dashboard/cash-deposit",
    iconActive: TransactIcon,
    icon: Transact2Icon,
    dropdownList: [
      {
        id: 1,
        title: "Cash Deposits",
        path: "/dashboard/cash-deposit",
        iconActive: Cloud2,
        icon: Cloud,
      },
    ],
  },
  {
    id: 4,
    title: "WithDdrawals",
    path: "/dashboard/cash-withdrawal",
    iconActive: Profile2,
    icon: Profile,
    dropdownList: [
      {
        id: 1,
        title: "Cash Withdrawals",
        path: "/dashboard/cash-withdrawal",
        iconActive: Cloud2,
        icon: Cloud,
      },
      {
        id: 2,
        title: "Bio-metrics",
        path: "/dashboard/bio-metrics-withdrawal",
        iconActive: ClearingActive,
        icon: ClearingIcon,
      },
    ],
  },
  {
    id: 5,
    title: "Transfer",
    path: "/dashboard/fcmb-2-fcmb",
    iconActive: Profile2,
    icon: Profile,
    dropdownList: [
      {
        id: 1,
        title: "FCMB to FCMB",
        path: "/dashboard/fcmb-2-fcmb",
        iconActive: Cloud2,
        icon: Cloud,
      },
      {
        id: 1,
        title: "FCMB to Others",
        path: "/dashboard/fcmb-2-other",
        iconActive: Cloud2,
        icon: Cloud,
      },
    ],
  },
  {
    id: 6,
    title: "Collections",
    path: "/dashboard/collections",
    iconActive: Profile2,
    icon: Profile,
    dropdownList: [
      {
        id: 1,
        title: "Collection",
        path: "/dashboard/collections",
        iconActive: Cloud2,
        icon: Cloud,
      },
    ],
  },
  {
    id: 7,
    title: "Easy Account",
    path: "/dashboard/home",
    iconActive: Profile2,
    icon: Profile,
    dropdownList: [
      {
        id: 1,
        title: "Cash Deposits",
        path: "/dashboard/home",
        iconActive: Cloud2,
        icon: Cloud,
      },
    ],
  },

  {
    id: 8,
    title: "Reports",
    path: "/dashboard/reports",
    iconActive: TransactIcon,
    icon: Transact2Icon,
  },
  {
    id: 8,
    title: "Limit",
    path: "/dashboard/limit",
    iconActive: TransactIcon,
    icon: Transact2Icon,
  },
  {
    id: 8,
    title: "Admin",
    path: "/dashboard/admin",
    iconActive: TransactIcon,
    icon: Transact2Icon,
  },
];

export const tableDatas: TableProps[] = [
  {
    sn: "1000",
    firstname: "233",
    lastname: "234",
  },
  {
    sn: "500",
    firstname: "234",
    lastname: "2",
  },
  {
    sn: "1000",
    firstname: "233",
    lastname: "234",
  },
  {
    sn: "500",
    firstname: "234",
    lastname: "2",
  },
  {
    sn: "1000",
    firstname: "233",
    lastname: "234",
  },
  {
    sn: "500",
    firstname: "234",
    lastname: "2",
  },
  {
    sn: "1000",
    firstname: "233",
    lastname: "234",
  },
  {
    sn: "500",
    firstname: "234",
    lastname: "2",
  },
];

export const TestDaa: ListProps[] = [
  {
    value: "Test",
    label: "Test",
  },
  {
    value: "Adetola",
    label: "Adetola",
  },
  {
    value: "Bamidele",
    label: "Bamidele",
  },
];
export const limitList: ListProps[] = [
  {
    value: 0,
    label: "One-Time",
  },
  {
    value: 1,
    label: "Set Expiry Date",
  },
];

export const collectionTypeList = [
  {
    value: "BUA",
    label: "BUA",
  },
  {
    value: "Dangote",
    label: "Dangote",
  },
];
export const roleList: ListProps[] = [
  {
    value: "Checker",
    label: "Checker",
  },
  {
    value: "Maker",
    label: "Maker",
  },
  {
    value: "Admin",
    label: "Admin",
  },
];

export const transactionData: cardComponentType[] = [
  {
    title: "Till Balance",
    amount: "0",
    img: Exemption,
    count: 0,
  },
  {
    title: "Deposits",
    amount: "0",
    img: Approve,
    count: 0,
  },
  {
    title: "Withdrawal",
    amount: "0",
    img: Inward,
    count: 0,
  },
  {
    title: "Inter Transfer",
    amount: "0",
    img: Outward,
    count: 0,
  },
  {
    title: "Intra Transfer",
    amount: "0",
    img: Finacle,
    count: 0,
  },
  {
    title: "Wallet- Wallet Transfer",
    amount: "0",
    img: Bill,
    count: 0,
  },
  {
    title: "Wallet- Account Transfer ",
    amount: "0",
    img: Finacle,
    count: 0,
  },
  {
    title: "Account- Wallet Transfer ",
    amount: "0",
    img: Bill,
    count: 0,
  },
];
export const depositData: cardComponentType[] = [
  {
    title: "Limit Exemption",
    amount: "0",
    img: Exemption,
    count: 0,
  },
  {
    title: "Approve Limit",
    amount: "0",
    img: Approve,
    count: 0,
  },
  {
    title: "Inward Report",
    amount: "0",
    img: Inward,
    count: 0,
  },
  {
    title: "Outward Report",
    amount: "0",
    img: Outward,
    count: 0,
  },
  {
    title: "Finacle Spool",
    amount: "0",
    img: Finacle,
    count: 0,
  },
];

export const transactionTableData: TableProps[] = [
  {
    sn: "1",
    receipt: 23242,
    source: "46745667456",
    destination: "3424646556",
    amount: 30000,
    currency: "NGN",
    date: "12-05-2023",
    narration: "Excellent!",
  },
  {
    sn: "2",
    receipt: 23242,
    source: "46745667456",
    destination: "3424646556",
    amount: 30000,
    currency: "NGN",
    date: "12-05-2023",
    narration: "Excellent!",
  },
];

export const currencyData: cardComponentType[] = [
  {
    title: "Currency (NGN)",
    amount: "22,300.00",
    img: nga,
    currency: "NGN",
  },
  {
    title: "Currency (USD)",
    amount: "2,200.00",
    img: usa,
    currency: "USD",
  },
  {
    title: "Currency (NGN)",
    amount: "22,300.00",
    img: uk,
    currency: "EUR",
  },
  {
    title: "Currency (GBP)",
    amount: "2,200.00",
    img: uk,
    currency: "GBP",
  },
];
export const ReportTableHeader: HeaderData[] = [
  {
    id: 1,
    title: "S/N",
  },
  {
    id: 2,
    title: "Receipt No",
  },
  {
    id: 3,
    title: "Source Account",
  },
  {
    id: 4,
    title: "Destination Account",
  },
  {
    id: 5,
    title: "Amount",
  },
  {
    id: 6,
    title: "Currency",
  },
  {
    id: 7,
    title: "Date",
  },
  {
    id: 8,
    title: "Approval Status",
  },
  {
    id: 9,
    title: "Print",
  },
  // {
  //   id: 9,
  //   title: "Action"
  // }
];

export const CollectionReportTableHeader: HeaderData[] = [
  {
    id: 1,
    title: "S/N",
  },
  {
    id: 2,
    title: "Transaction Id",
  },
  {
    id: 3,
    title: "Account Number",
  },
  {
    id: 4,
    title: "Customer Number",
  },
  {
    id: 5,
    title: "Amount",
  },
  {
    id: 6,
    title: "Currency",
  },
  {
    id: 7,
    title: "Date",
  },
  {
    id: 8,
    title: "Approval Status",
  },
];

export const buaCollectionReportTableHeader: HeaderData[] = [
  {
    id: 1,
    title: "S/N",
  },
  {
    id: 2,
    title: "Order Id",
  },
  {
    id: 3,
    title: "Credit Account",
  },
  {
    id: 4,
    title: "Teller Number",
  },
  {
    id: 5,
    title: "Amount",
  },
  {
    id: 6,
    title: "Date Created",
  },
  {
    id: 7,
    title: "Date Approved",
  },
  {
    id: 8,
    title: "Status",
  },
  {
    id: 9,
    title: "View",
  },
];

export const ApprovedReportTableHeader: HeaderData[] = [
  {
    id: 2,
    title: "Receipt No",
  },
  {
    id: 3,
    title: "Source Account",
  },
  {
    id: 4,
    title: "Destination Account",
  },
  {
    id: 5,
    title: "Amount",
  },
  {
    id: 6,
    title: "Currency",
  },
  {
    id: 7,
    title: "Date",
  },
  {
    id: 8,
    title: "Action",
  },
];

export const instrumentType: DropDownOptions[] = [
  {
    id: 1,
    label: "CHEQUE(CHQ)",
    value: "CHQ",
  },
  {
    id: 2,
    label: "CMS DRAFT(CMS)",
    value: "CMS",
  },
  {
    id: 3,
    label: "DEMAND DRAFT(DDS)",
    value: "DDS",
  },
  {
    id: 4,
    label: "APPROVED ELECTRONIC MAIL(EMAIL)",
    value: "EMAIL",
  },
  {
    id: 5,
    label: "LOOSE CHEQUE(LCHQ)",
    value: "LCHQ",
  },
  {
    id: 6,
    label: "OTHERS(OTHRS)",
    value: "OTHRS",
  },
  {
    id: 7,
    label: "SAVINGS AC WITHDRAWAL SLIP(WSLIP)",
    value: "WSLIP",
  },
];
export const depositType: DropDownOptions[] = [
  {
    id: 1,
    label: "Cash Deposit",
    value: "Cash",
  },
  // {
  //   id: 2,
  //   label: "Cheque Deposit",
  //   value: "Cheque",
  // },
];

export const currencyType: DropDownOptions[] = [
  {
    id: 1,
    label: "Naira(NGN)",
    value: "NGN",
  },
  {
    id: 2,
    label: "Dollars(USD)",
    value: "USD",
  },
  {
    id: 3,
    label: "Pounds(GBP)",
    value: "GBP",
  },
  {
    id: 4,
    label: "Euros(EUR)",
    value: "EUR",
  },
];
export const transactionItems: DropDownOptions[] = [
  {
    value: "IntraBank",
    id: 1,
    label: "Intra Bank",
  },
  {
    value: "InterBank",
    id: 2,
    label: "Inter Bank",
  },
];
export const transactionTypes: DropDownOptions[] = [
  {
    label: "Cash Deposit",
    value: "Cash",
  },
  {
    label: "Cheque Deposit",
    value: "Cheque Deposit",
  },
  {
    label: "Collection",
    value: "Collection",
  },
  {
    label: "Cash Withdrawal",
    value: "Cash Withdrawal",
  },
  {
    label: "Cheque Withdrawal",
    value: "Cheque Withdrawal",
  },
  {
    label: "Transfer(InterBank)",
    value: "InterBank",
  },
  {
    label: "Transfer(IntraBank)",
    value: "IntraBank",
  },
];

export const collectionName: DropDownOptions[] = [
  {
    label: "BUA",
    value: "BUA",
  },
  {
    label: "DANGOTE",
    value: "DANGOTE",
  },
];

export const usersTableHeader: any = [
  {
    id: 1,
    title: "Username",
  },
  // {
  //   id: 2,
  //   title: 'Role'
  // },
  {
    id: 2,
    title: "Status",
  },
  {
    id: 3,
    title: "Date Created",
  },
];
export const tableHeader: any = [
  {
    id: 2,
    title: "Receipt No",
  },
  {
    id: 3,
    title: "Currency",
  },
  {
    id: 4,
    title: "Destination Account",
  },
  {
    id: 5,
    title: "Amount",
  },
  {
    id: 6,
    title: "Currency",
  },
  {
    id: 7,
    title: "Date",
  },
  {
    id: 8,
    title: "Narration",
  },
  {
    id: 9,
    title: "Action",
  },
];

export const approveOptions: any = [
  {
    label: "True",
    value: true,
  },
  {
    label: "False",
    value: false,
  },
];
export const roleHeader: any = [
  {
    id: 1,
    title: "Role",
  },
  {
    id: 2,
    title: "Credit Limit",
  },
  {
    id: 3,
    title: "Credit Limit FCY",
  },
  {
    id: 4,
    title: "Debit Limit",
  },
  {
    id: 5,
    title: "Debit Limit FCY",
  },
  {
    id: 5,
    title: "Date Created",
  },
  {
    id: 6,
    title: "Action",
  },
];

export const depositSchema = yup.object().shape({
  accountName: yup.string().required("Account name is required"),
  depositType: yup.string().required("DepositType is required"),
  customerAccountDescription: yup
    .string()
    .required("customer Account Description is required"),
  bvn: yup.string().required("bvn is required"),
  availableBalance: yup.string().required("available balance is required"),
  schemaCode: yup.string().required("schema code is required"),
  beneficiaryAccountName: yup.string().required("schema code is required"),
  beneficiaryDepositType: yup.string().required("deposit type is required"),
  beneficiaryBvn: yup.string().required("bvn is required"),
  beneficiaryAccountDescription: yup
    .string()
    .required("ac description is required"),
  beneficiaryAvailableBalance: yup
    .string()
    .required("available balance is required"),
  beneficiarySchemaCode: yup.string().required("schema code is required"),
  beneficiaryAccount: yup.string().required("beneficiary account is required"),
});

export const interSchema = yup.object().shape({
  accountName: yup.string().required("Account name is required"),
  depositType: yup.string().required("DepositType is required"),
  customerAccountDescription: yup
    .string()
    .required("customer Account Description is required"),
  bvn: yup.string().required("bvn is required"),
  availableBalance: yup.string().required("available balance is required"),
  schemaCode: yup.string().required("schema code is required"),
  beneficiaryAccountName: yup.string().required("schema code is required"),
  beneficiaryDepositType: yup.string().required("deposit type is required"),
  beneficiaryBvn: yup.string().required("bvn is required"),
  beneficiaryAccountDescription: yup
    .string()
    .required("ac description is required"),
  beneficiaryAvailableBalance: yup
    .string()
    .required("available balance is required"),
  beneficiarySchemaCode: yup.string().required("schema code is required"),
  beneficiaryAccount: yup.string().required("beneficiary account is required"),
  weeklyLimit: yup.string().required("weekly limit is required"),
  dailyLimit: yup.string().required("daily limit is required"),
});

export const updateRoleSchema = yup.object().shape({
  roleName: yup.string().required("debit account is required"),
  roleDescription: yup.string().required("Account name is required"),
  debitLimt: yup
    .number()
    .test(
      "Is positive?",
      "The amount must be a positive number!",
      (value) => (value as number) >= 0
    )
    .required("beneficiary account is required"),
  debitLimtFCY: yup
    .number()
    .test(
      "Is positive?",
      "The amount must be a positive number!",
      (value) => (value as number) >= 0
    )
    .required("Account type is required"),
  creditLimit: yup
    .number()
    .test(
      "Is positive?",
      "The amount must be a positive number!",
      (value) => (value as number) >= 0
    )
    .required("beneficiary Account Name is required"),
  creditLimitFCY: yup
    .number()
    .test(
      "Is positive?",
      "The amount must be a positive number!",
      (value) => (value as number) >= 0
    )
    .required("beneficiary Institution Code is required"),
});

export const withdrawalSchema = yup.object().shape({
  beneficiaryName: yup.string().required("Beneficiary name is required"),
  depositType: yup.string().required("DepositType is required"),
  middleName: yup.string().required("middlename is required"),
  email: yup.string().required("email is required"),
  availableBalance: yup.string().required("available balance is required"),
  schemaCode: yup.string().required("schema code is required"),
  accountType: yup.string().required("Account type is required"),
  weeklyLimit: yup.string().required("weekly limit is required"),
  dailyLimit: yup.string().required("daily limit is required"),
});

export const buaSchema = yup.object().shape({
  debitAccountName: yup.string().required(" Account Name is required"),
});

export const denominationList: DeProp[] = [
  { denomination: 1000, quantity: 0, amount: 0 },
  { denomination: 500, quantity: 0, amount: 0 },
  { denomination: 200, quantity: 0, amount: 0 },
  { denomination: 100, quantity: 0, amount: 0 },
  { denomination: 50, quantity: 0, amount: 0 },
  { denomination: 20, quantity: 0, amount: 0 },
  { denomination: 10, quantity: 0, amount: 0 },
  { denomination: 5, quantity: 0, amount: 0 },
];
export const customerInfoData: CustomerInfoProp = {
  accountName: "",
  amount: 0,
  depositorName: "",
  phoneNumber: "",
  instrumentNumber: "",
  chequeNo: "",
  remark: "",
  narration: "",
  accountNumber: "",
};
export const ErrorHandler = (error: any) => {
  toast.error(
    error?.data?.Message
      ? error?.data?.Message
      : error?.data?.message
      ? error?.data?.message
      : "Error completing operation, please contact admin."
  );
};
export const collectionInfoData: CollectionInfoProp = {
  tellerNum: "",
  amount: 0,
  depositorName: "",
  depositorPhoneNo: "",
  debitAccountNumber: "",
};

export const buaInfoData: BuaInfoProp = {
  depositorName: "",
  depositorPhoneNo: "",
  remark: "",
  narration: "",
};

export const withdrawalType = [
  {
    id: 1,
    label: "Cheque",
    value: "CHQ",
  },
  {
    id: 2,
    label: "CMS Draft",
    value: "CMS",
  },
  {
    id: 3,
    label: "Demand Draft",
    value: "DDS",
  },
  {
    id: 4,
    label: "Approved Electronic Mail",
    value: "EMAIL",
  },
  {
    id: 5,
    label: "Loose Cheque",
    value: "LCHQ",
  },
  {
    id: 6,
    label: "Others",
    value: "OTHERS",
  },
  {
    id: 7,
    label: "Savings Account Withdrawal Slip",
    value: "WSLIP",
  },
];

export const WithDdrawalError = {
  amount: "",
  depositorName: "",
};

export const BuaErrorStruct = {
  amount: "",
  depositorName: "",
};
export const collectionyType: any = [
  {
    id: 1,
    value: "Cement",
    label: "Cement",
  },
  {
    id: 2,
    value: "Foods",
    label: "Foods",
  },
];

export const fingerType: any = [
  {
    id: 1,
    value: "Right Thumb",
    label: "Right Thumb",
  },
  {
    id: 2,
    value: "Right Index",
    label: "Right Index",
  },
  {
    id: 3,
    value: "Right Middle",
    label: "Right Middle",
  },
  {
    id: 4,
    value: "Right Ring",
    label: "Right Ring",
  },
  {
    id: 5,
    value: "Right Little",
    label: "Right Little",
  },
  {
    id: 6,
    value: "Left Thumb",
    label: "Left Thumb",
  },
  {
    id: 7,
    value: "Left Index",
    label: "Left Index",
  },
  {
    id: 8,
    value: "Left Middle",
    label: "Left Middle",
  },
  {
    id: 9,
    value: "Left Ring",
    label: "Left Ring",
  },
  {
    id: 10,
    value: "Left Little",
    label: "Left Little",
  },
];

export const collectionType: any = [
  {
    id: 1,
    value: "Collection",
    label: "Collection",
  },
  {
    id: 2,
    value: "Special Collection",
    label: "Special Collection",
  },
];

export const collectionIdType: any = [
  {
    id: 1,
    value: "No ID",
    label: "No ID",
  },
  {
    id: 2,
    value: "Order ID",
    label: "Order ID",
  },
];

export const sortTableData = (a: any, b: any) => {
  var dateA = new Date(a.date).getTime();
  var dateB = new Date(b.date).getTime();
  return dateA - dateB ? 1 : -1;
};

export const fetchPublicIP = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error(error);
    return null;
  }
};

import React, { useCallback, useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import PrimaryInput from "../../components/PrimaryInput";
import DropDownC from "../../components/PrimaryInput/dropDown";
import { convertAmountToWords } from "../../utils/amountWriter";
import GeneralModal from "../../components/Modal/GeneralModal";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/user.svg";
import { ReactComponent as InputClose } from "../../assets/svg/inputClose.svg";
import { ReactComponent as PhoneICon } from "../../assets/svg/phone-icon.svg";
import SuccessModal from "../../components/Modal/SuccessModal";
import { currencyType, depositType, ErrorHandler } from "../../utils/config";
import {
  Container,
  FieldWrap,
  ComponentWrapper,
  Top,
  TopTitle,
  IconWrap,
  HeaderText,
  ContainerDepo,
  Status,
  StatusHolder,
  LeftContainerDepo,
  RightContainerDepo,
  ButtonWrap,
  Input,
  Radio,
  Instruction,
  InputDiv,
  LeftD,
  RightD,
  DHeader,
  Text,
  FieldWrapD,
  DenominationContainer,
  TopContainer,
  DenominationWrap,
  DetailsWrapper,
} from "./style";
import PrimaryButton from "../../components/Buttons/Button";
import { depositSchema } from "../../utils/config";
import { HeaderComponent } from "../../components/Withdrawal/style";
import { useSelector } from "react-redux";
import {
  useGetAccDetailsMutation,
  useGetReceiptMutation,
  usePostDepositMutation,
  useViewMandateMutation,
} from "../../services/transaction/transaction";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DenominationInput from "../../components/PrimaryInput/denInput";
import toast from "react-hot-toast";
import { KeyValueMapping } from "../CashWithdrawals/ViewTransDetails";
import PDFGenerator from "../../components/PDFGenerator/PdfGenerator";
import { GetReceipt } from "../../services/transaction/types";
import TopCards from "../../components/Cards/TopCards";
import PuffLoader from "../../components/Loader";
import DateComponent from "../../components/Date/Date";
import ReceiptView from "../../components/Receipt/Receipt";
import DReceiptView from "../../components/DReceipt/Receipt";
import TextArea from "../../components/TextArea";

const CashDeposit = () => {
  const [isOpenTransModal, setIsOpenTransModal] = useState(false);
  const [transStatus, setTransStatus] = useState(false);
  const [transactionReceipt, setTransactionReceipt] = useState(false);
  const [transId, setTransId] = useState<string>("");
  const [getAccount, mutationState] = useGetAccDetailsMutation();
  const [imageData, setImageData] = useState("");
  const [openMandate, setOpenMandate] = useState(false);
  const accFetchLoading = mutationState.isLoading;
  const [isStatus, setStatus] = useState("");
  const currentDate = new Date();
  const [generatePDF, setGeneratePDF] = useState(false);
  const [viewMandate, mandateMutationState] = useViewMandateMutation();
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(depositSchema),
  });

  const [customerInfo, setCustomerInfo] = useState<{
    accountName?: string;
    amount?: string | number;
    depositorName?: string;
    phoneNumber?: string;
    chequeNo?: string;
    schemeCode?: string;
    bvn?: string;
    customerType?: string;
    narration?: string;
    remark?: string;
    employerId: string;
    currency: string;
    accountNumber?: string;
    accountNumberType?: "salary" | "savings" | "fixed deposit";
  }>({
    accountName: "",
    amount: 0,
    depositorName: "",
    phoneNumber: "",
    chequeNo: "",
    schemeCode: "",
    bvn: "",
    customerType: "",
    employerId: "",
    currency: "",
    accountNumber: "",
    accountNumberType: "salary",
  });

  const staffInfo = useSelector(
    (state: any) => state?.StaffDataReducer?.staffInfo
  );
  const branchcode = useSelector(
    (state: any) => state?.StaffDataReducer?.userDetails?.data?.branchCode
  );

  const role = staffInfo?.user?.role;
  const bankUser = staffInfo?.user?.displayName ?? ";";
  const [accountName, setAccountName] = useState("");
  const [transMessage, setTransMessage] = useState("");
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [accountOwner, setAccountOwner] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [ten, setTen] = useState({
    denomination: 10,
    quantity: "0",
    amount: 0,
  });
  const [amount, setAmount] = useState(0);
  const [twenty, setTwenty] = useState({
    denomination: 20,
    quantity: "0",
    amount: 0,
  });
  const [twoHundred, setTwoHundred] = useState({
    denomination: 200,
    quantity: "0",
    amount: 0,
  });
  const [hundred, setHundred] = useState({
    denomination: 100,
    quantity: "0",
    amount: 0,
  });
  const [fifty, setFifty] = useState({
    denomination: 50,
    quantity: "0",
    amount: 0,
  });
  const [fiveHundred, setFiveHundred] = useState({
    denomination: 500,
    quantity: "0",
    amount: 0,
  });
  const [thousand, setThousand] = useState({
    denomination: 1000,
    quantity: "0",
    amount: 0,
  });
  const [five, setFive] = useState({
    denomination: 5,
    quantity: "0",
    amount: 0,
  });
  const [selectedDepositType, setDepositType] = useState("");
  const [postDeposit, { isLoading }] = usePostDepositMutation();
  //const getAnalytics = useGetAnalyticsQuery(username);
  const [currency, setCurrency] = useState("");
  const formatAmount = (currency: string, amount: number) => {
    if (currency === "NGN") {
      return `${convertAmountToWords(amount)} Naira`;
    }
    if (currency === "USD") {
      return `${convertAmountToWords(amount)} Dollars`;
    }
    if (currency === "GBP") {
      return `${convertAmountToWords(amount)} Pounds`;
    }
    if (currency === "EUR") {
      return `${convertAmountToWords(amount)} Euros`;
    }
  };
  const [error, setError] = useState<{
    amount: string;
    depositorName?: string;
    currency: string;
    selectDate: string;
    accountName: string;
    instrument: string;
    accountNumber: string;
    selectedDepositType: string;
    phoneNumber: string | undefined;
  }>({
    amount: "",
    depositorName: "",
    currency: "",
    accountName: "",
    selectDate: "",
    instrument: "",
    accountNumber: "",
    phoneNumber: "",
    selectedDepositType: "",
  });
  const [transReceipt, setTransReceiptData] = useState<
    GetReceipt | undefined
  >();
  const [openFields, setOpenFields] = useState<boolean>(false);
  const [getReceipt] = useGetReceiptMutation();
  const [selectDate, setSelectedDate] = useState(
    currentDate.toJSON().slice(0, 10).toString()
  );
  const [chequeNo, setCheck] = useState("");
  const [fiveArray, setFiveArray] = useState<(typeof five)[]>([]);
  const [tenArray, setTenArray] = useState<(typeof ten)[]>([]);
  const [twentyArray, setTwentyArray] = useState<(typeof twenty)[]>([]);
  const [fiftyArray, setFiftyArray] = useState<(typeof fifty)[]>([]);
  const [hundredArray, setHundredArray] = useState<(typeof hundred)[]>([]);
  const [twoHundredArray, setTwoHundredArray] = useState<(typeof twoHundred)[]>(
    []
  );
  const [fiveHundredArray, setFiveHundredArray] = useState<
    (typeof fiveHundred)[]
  >([]);
  const [thousandArray, setThousandArray] = useState<(typeof thousand)[]>([]);
  const handleDenominationChange = (
    denomination: number,
    quantity: string | number,
    amount: string | number,
    setArray: any
  ) => {
    setArray((prevArray: any[]) => {
      if (quantity === 0) {
        return prevArray.filter(
          (item: any) => item.denomination !== denomination
        );
      } else {
        return [...prevArray, { denomination, quantity, amount }];
      }
    });
  };

  useEffect(() => {
    handleDenominationChange(
      5,
      five.quantity,
      5 * +five.quantity,
      setFiveArray
    );
    handleDenominationChange(10, ten.quantity, +ten.quantity * 10, setTenArray);
    handleDenominationChange(
      20,
      twenty.quantity,
      +twenty.quantity * 20,
      setTwentyArray
    );
    handleDenominationChange(
      50,
      fifty.quantity,
      50 * +fifty.quantity,
      setFiftyArray
    );
    handleDenominationChange(
      100,
      hundred.quantity,
      100 * +hundred.quantity,
      setHundredArray
    );
    handleDenominationChange(
      200,
      twoHundred.quantity,
      200 * +twoHundred.quantity,
      setTwoHundredArray
    );
    handleDenominationChange(
      500,
      fiveHundred.quantity,
      500 * +fiveHundred.quantity,
      setFiveHundredArray
    );
    handleDenominationChange(
      1000,
      thousand.quantity,
      1000 * +thousand.quantity,
      setThousandArray
    );
  }, [five, ten, twenty, fifty, hundred, twoHundred, fiveHundred, thousand]);

  const getLastItemInArray: any = (xArray: any[]) => {
    let lastIndex = xArray.length - 1;
    let finalArray = xArray[lastIndex];
    let result = [finalArray];
    return result;
  };
  const denominationArrays = [
    fiveArray,
    tenArray,
    twentyArray,
    fiftyArray,
    hundredArray,
    twoHundredArray,
    fiveHundredArray,
    thousandArray,
  ];
  const combinedArray = denominationArrays
    .map((array) => getLastItemInArray(array))
    .flat();
  const filteredArray = combinedArray.filter((item) => item?.quantity !== "0");

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    if (name === "accountNumber") {
      setAccountNumber(value.replace(/\s/g, ""));
    } else {
      setCustomerInfo((prevState: any) => ({
        ...prevState,
        [event.target.name]: event.target.value,
      }));
    }
  };
  const postDepositDetails = async () => {
    const payload = {
      isOwner: accountOwner,
      accountNumber: accountNumber,
      depositType: selectedDepositType,
      amount: amount,
      currencyType: currency,
      depositorName: accountOwner ? accountName : customerInfo?.depositorName,
      depositorPhoneNo: customerInfo?.phoneNumber,
      amountInWords: formatAmount(currency, amount),
      narration: customerInfo?.narration,
      remark: customerInfo?.remark,
      denominations: filteredArray,
    };

    try {
      const response = await postDeposit(payload).unwrap();

      if (response.statusCode === 200) {
        setIsOpenTransModal(false);
        setTransStatus(true);
        setTransactionStatus(true);

        setTransMessage(response?.message);
        if (response?.data?.tran_Id) {
          setTransId(response?.data?.tran_Id);
        }
        closeReceipt();
        setOpenFields(false);
      }
    } catch (err: any) {
      setIsOpenTransModal(false);
      ErrorHandler(err);
    }
  };
  const onSelectAccountOwner = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountOwner(event.target.value === "yes");
  };

  const closeReceipt = () => {
    setCustomerInfo({
      ...customerInfo,
      phoneNumber: "",
      depositorName: "",
      remark: "",
      narration: "",
    });

    setDepositType("");
    setCurrency("");
    setAccountNumber("");
    setAccountName("");
    setAmount(0);
    setFive({ ...five, quantity: "0" });
    setTen({ ...ten, quantity: "0" });
    setTwenty({ ...twenty, quantity: "0" });
    setFifty({ ...fifty, quantity: "0" });
    setHundred({ ...hundred, quantity: "0" });
    setTwoHundred({ ...twoHundred, quantity: "0" });
    setFiveHundred({ ...fiveHundred, quantity: "0" });
    setThousand({ ...thousand, quantity: "0" });
    setCustomerInfo({ ...customerInfo, phoneNumber: "", depositorName: "" });
    setTransactionReceipt(false);

    setValue("accountName", "", {
      shouldValidate: true,
    });
    setValue("depositType", "", {
      shouldValidate: true,
    });

    setValue("customerAccountDescription", "", {
      shouldValidate: true,
    });
    setValue("bvn", "", {
      shouldValidate: true,
    });

    setValue("availableBalance", "", {
      shouldValidate: true,
    });
    setValue("schemaCode", "", {
      shouldValidate: true,
    });
  };
  const totalAmount =
    +five.quantity * 5 +
    +ten.quantity * 10 +
    +twenty.quantity * 20 +
    +fifty.quantity * 50 +
    +hundred.quantity * 100 +
    +twoHundred.quantity * 200 +
    +fiveHundred.quantity * 500 +
    +thousand.quantity * 1000;

  const onOpenTransDetail = useCallback(() => {
    const { phoneNumber } = customerInfo;
    const updatedError: any = {};
    if (!accountNumber) {
      updatedError.accountNumber = "Account required";
    }
    if (!selectedDepositType) {
      updatedError.selectedDepositType = "Select Deposit type";
    }
    if (!accountName) {
      updatedError.accountName = "Depositor Name is required";
    }
    if (!phoneNumber || phoneNumber.length !== 11) {
      updatedError.phoneNumber = "Phone is required, 11 digits";
    }
    if (!currency) {
      updatedError.currency = "Select currency";
    }
    if (!amount || amount === 0) {
      updatedError.amount = "Amount is required, SELECT DENOMINATION ALSO";
    }
    if (role === "TellerNew" && amount > 50000) {
      updatedError.amount = "Maximum deposit amount is 50,000";
    }
    if (selectDate === "") {
      updatedError.selectDate = "Instrument Date is required";
    }
    if (+amount !== +totalAmount) {
      toast.error("Ensure amount and total amount in denominations are equal");
    }

    if (filteredArray.length === 0) {
      toast.error("Fill Denominations");
    }
    setError(updatedError);

    if (Object.keys(updatedError).length === 0 && +amount === +totalAmount) {
      setIsOpenTransModal(true);
    }
  }, [
    accountName,
    accountNumber,
    amount,
    currency,
    customerInfo,
    filteredArray.length,
    selectDate,
    selectedDepositType,
    totalAmount,
  ]);

  const transData = {
    Depositor: customerInfo?.depositorName
      ? customerInfo?.depositorName
      : accountName,
    Beneficiary: accountName,
    "Deposit Type": selectedDepositType,
    "Beneficiary Account Number": accountNumber,
    Amount: new Intl.NumberFormat().format(amount),
    "Depositor's Phone Number": customerInfo?.phoneNumber,
    Currency: currency,
    "Branch Code": "214",
    "Transaction Date": new Date(Date.now()).toLocaleDateString(),
    "Received by": bankUser,
  };

  const generateReceipt = async () => {
    const data = {
      transactionType: "Deposit",
      transactionId: transId,
    };
    try {
      const response = await getReceipt(data).unwrap();
      if (response.statusCode === 200) {
        setTransReceiptData(response?.data);
        setTransStatus(false);
        setTransactionStatus(false);
        setTransactionReceipt(true);
        setGeneratePDF(true);
      }
    } catch (error: any) {
      if (error?.status === 406) {
        ErrorHandler(error);
      } else {
        toast.error(error?.data?.title);
      }
    }
  };

  useEffect(() => {
    const handleCFetchAccount = async () => {
      try {
        if (accountNumber.length === 10) {
          const response = await getAccount({
            accountNo: accountNumber,
          }).unwrap();

          setOpenFields(true);
          const firstName =
            response?.firstName !== null ? response?.firstName : "";
          const lastName =
            response?.lastName !== null ? response?.lastName : "";

          setStatus(response?.status as string);
          const beneficiaryName = `${firstName} ${lastName}`;
          setValue("accountName", beneficiaryName, {
            shouldValidate: true,
          });
          setAccountName(beneficiaryName);
          setValue("depositType", response?.customerType as string, {
            shouldValidate: true,
          });
          setValue(
            "customerAccountDescription",
            response?.accountType as string,
            {
              shouldValidate: true,
            }
          );
          setValue("bvn", response?.bvn as string, {
            shouldValidate: true,
          });
          setValue(
            "availableBalance",
            new Intl.NumberFormat("ja-JP", {
              style: "currency",
              currency: response?.currency,
            }).format(response?.availableBalance as number),
            {
              shouldValidate: true,
            }
          );
          setValue("schemaCode", response?.schemeCode as string, {
            shouldValidate: true,
          });
        }
      } catch (error: any) {
        ErrorHandler(error);
      }
    };
    handleCFetchAccount();
  }, [accountNumber.length, accountNumber, getAccount, setValue]);

  const handleClose = () => {
    setIsOpenTransModal(false);
  };

  const handleCloseReceipt = () => {
    setTransactionReceipt(false);
    setTransactionStatus(false);
    setGeneratePDF(false);
    window.location.reload();
  };

  const handleCloseMandate = () => {
    setOpenMandate(false);
  };

  const handleViewMandate = async () => {
    const value = {
      accountNumber: accountNumber,
    };

    const response = await viewMandate(value).unwrap();
    if (response?.statusCode === 200) {
      toast.success(response.message);

      if (response?.data !== null) {
        const imageString: string | undefined = response?.data?.images;
        setImageData(imageString as string);
        setOpenMandate(true);
      } else {
        setImageData("");
        setOpenMandate(false);
      }

      // const decodedImage = atob(imageData as string);
    }
  };

  return (
    <>
      <Header title="Deposits/Cash" />
      <ComponentWrapper>
        <TopCards />

        <Container>
          <HeaderComponent>
            <HeaderText>Customer Information</HeaderText>
            <div style={{ marginTop: "24px", width: "538px" }}>
              <PrimaryInput
                name="accountNumber"
                type="text"
                rightIcon={<></>}
                placeholder="Enter Account Number"
                error={error.accountNumber}
                label="Account Number"
                check={""}
                padding={""}
                leftIcon={<SearchIcon />}
                onChange={(e) => setAccountNumber(e.target.value)}
                value={accountNumber}
                register={register}
              />
            </div>
          </HeaderComponent>
          {accFetchLoading ? (
            <PuffLoader />
          ) : openFields &&
            accountNumber.length === 10 &&
            mutationState.data !== undefined ? (
            <>
              <StatusHolder>
                <Status
                  color={isStatus === "ACTIVE" ? "#01B272" : "#C43C20"}
                  backgroundColor={
                    isStatus === "ACTIVE"
                      ? "rgba(1, 178, 114, 0.2)"
                      : "rgba(231, 175, 164, 0.3)"
                  }
                >
                  {isStatus === "ACTIVE" ? "Active" : "Inactive"}
                </Status>
              </StatusHolder>

              <FieldWrap>
                <PrimaryInput
                  name="accountName"
                  type={"text"}
                  placeholder="Account Name"
                  rightIcon={<></>}
                  error={undefined}
                  label="Account Name"
                  check={""}
                  padding={""}
                  disabled={true}
                  leftIcon={<InputClose />}
                  onChange={() => {}}
                  value={undefined}
                  register={register}
                />
                <PrimaryInput
                  name="depositType"
                  type={"text"}
                  placeholder="customerType"
                  rightIcon={<></>}
                  label="Customer Type"
                  check={""}
                  register={register}
                  padding={""}
                  disabled={true}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
              </FieldWrap>
              <FieldWrap>
                <PrimaryInput
                  name="customerAccountDescription"
                  type={"text"}
                  placeholder="Customer Account Description"
                  rightIcon={<></>}
                  disabled={true}
                  label="Customer Account Description"
                  check={""}
                  padding={""}
                  register={register}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
                <PrimaryInput
                  name="bvn"
                  type="text"
                  placeholder={"Customer Bvn"}
                  rightIcon={<></>}
                  label="Customer Bvn"
                  disabled={true}
                  check={""}
                  register={register}
                  padding={""}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
              </FieldWrap>

              <FieldWrap>
                <PrimaryInput
                  name={"availableBalance"}
                  type="text"
                  placeholder={"Available Balance"}
                  rightIcon={<></>}
                  label="Available Balance"
                  check={""}
                  disabled={true}
                  register={register}
                  padding={""}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
                <PrimaryInput
                  name={"schemaCode"}
                  type="text"
                  placeholder="Schema Code"
                  rightIcon={<></>}
                  disabled={true}
                  label="Schema Code"
                  check={""}
                  register={register}
                  padding={""}
                  leftIcon={<InputClose />}
                  value={undefined}
                  onChange={() => {}}
                />
              </FieldWrap>

              <FieldWrap>
                <ButtonWrap>
                  <div>
                    <PrimaryButton
                      background={"#ffffff"}
                      color={"#5c2684"}
                      width={150}
                      title={"View Mandate"}
                      icon={""}
                      loaderColor="#5c2684"
                      loading={false}
                      isLoading={mandateMutationState.isLoading}
                      leftIconName={<></>}
                      rightIconName={<></>}
                      onClick={handleViewMandate}
                      border={"1px solid #5c2684"}
                    />
                  </div>
                </ButtonWrap>
              </FieldWrap>
            </>
          ) : null}
        </Container>

        <ContainerDepo>
          <LeftContainerDepo>
            <HeaderText>Cash Deposits Information</HeaderText>
            <Instruction>
              Is the depositor the account owner
              <InputDiv>
                <Radio>
                  <Input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="yes"
                    checked={accountOwner}
                    onChange={onSelectAccountOwner}
                  />
                    <label>Yes</label>
                </Radio>
                <Radio>
                  <Input
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="no"
                    checked={!accountOwner}
                    onChange={onSelectAccountOwner}
                  />
                    <label>No</label>
                </Radio>
              </InputDiv>
            </Instruction>
            <PrimaryInput
              name="depositorName"
              type="text"
              placeholder="Enter Name"
              rightIcon={<></>}
              error={error.accountName}
              label="Depositor’s Name"
              check={""}
              padding={""}
              leftIcon={<UserIcon />}
              onChange={onHandleChange}
              value={accountOwner ? accountName : customerInfo?.depositorName}
            />
            <PrimaryInput
              name="phoneNumber"
              maxLength={11}
              type="number"
              inputMode="numeric"
              placeholder="Enter Phone Number"
              rightIcon={<></>}
              error={error.phoneNumber}
              label="Depositor’s Phone Number"
              check={""}
              padding={""}
              leftIcon={<PhoneICon />}
              onChange={onHandleChange}
              value={customerInfo?.phoneNumber}
            />
            <DropDownC
              value={currency}
              placeholder="Select Currency"
              options={currencyType}
              errorMessage={error.currency}
              label={"Currency"}
              setSelectedItem={(value: any) => setCurrency(value)}
              setOptionValue={() => {}}
              setSelectedItems={() => {}}
              // setOptionValue={setCurrencyValue}
              // setSelectedItem={()=>{}}
              //setSelectedItems={setCurrency}
            />
            <DropDownC
              value={selectedDepositType}
              placeholder="Select Deposit Type"
              options={depositType}
              setOptionValue={() => {}}
              errorMessage={error.selectedDepositType}
              label={"Deposit Type"}
              setSelectedItems={() => {}}
              setSelectedItem={(value: any) => setDepositType(value)}
            />

            {selectedDepositType === "Cheque" && (
              <PrimaryInput
                name="chequeNo"
                type="text"
                placeholder="Enter Cheque number"
                rightIcon={<></>}
                error={undefined}
                label="Cheque/Ref No"
                check={""}
                padding={""}
                leftIcon={<InputClose />}
                value={chequeNo}
                onChange={(e) => setCheck(e.target.value)}
              />
            )}
            <DateComponent
              showModal={false}
              onClosed={() => {}}
              value={selectDate}
              handleDateChange={(e: any) => setSelectedDate(e.target.value)}
              selectDate={setSelectedDate}
              label={"Instrument Date"}
              error={error?.selectDate}
            />
            <PrimaryInput
              name="amount"
              type="number"
              inputMode="numeric"
              placeholder="Enter Amount"
              rightIcon={<></>}
              error={error?.amount}
              label="Amount *"
              check={""}
              padding={""}
              leftIcon={<></>}
              onChange={(e) => {
                const newValue = Math.max(0, parseInt(e.target.value) ?? 0);
                // const newValue = parseInt(e.target.value, 10) ?? 0;
                setAmount(newValue);
              }}
              value={amount}
            />

            <TextArea
              label={"Remark"}
              name={"remark"}
              padding={0}
              placeholder={"Enter remark"}
              text={""}
              // error={error?.remark}
              onChange={onHandleChange}
              value={customerInfo?.remark}
            />

            <TextArea
              label={"Narration"}
              name={"narration"}
              padding={0}
              placeholder={"Enter narration"}
              text={""}
              // error={error?.narration}
              onChange={onHandleChange}
              value={customerInfo?.narration}
            />

            <ButtonWrap>
              <PrimaryButton
                background={""}
                type="button"
                color={""}
                width={150}
                title={"Post Transaction"}
                loading={false}
                leftIconName={<></>}
                rightIconName={<></>}
                onClick={onOpenTransDetail}
                border={"#5C2684"}
              />
            </ButtonWrap>
          </LeftContainerDepo>
          <RightContainerDepo>
            <DenominationContainer>
              <TopContainer>
                <DHeader>Denomination</DHeader>
                <DHeader>Quantity</DHeader>
              </TopContainer>
              <DenominationWrap>
                <Text>1000</Text>
                <DenominationInput
                  name={""}
                  type={""}
                  placeholder={""}
                  rightIcon={<></>}
                  label={""}
                  value={thousand.quantity}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10) || 0;
                    const quantity = newQuantity.toString();
                    setThousand({
                      denomination: 1000,
                      quantity: quantity,
                      amount: thousand.denomination * e.target.value,
                    });
                  }}
                  check={""}
                  padding={""}
                  leftIcon={<></>}
                />{" "}
              </DenominationWrap>
              <DenominationWrap>
                <Text>500</Text>
                <DenominationInput
                  name={""}
                  type={""}
                  placeholder={""}
                  rightIcon={<></>}
                  label={""}
                  check={""}
                  value={fiveHundred.quantity}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10) || 0;
                    const quantity = newQuantity.toString();
                    setFiveHundred({
                      denomination: 500,
                      quantity: quantity,
                      amount: fiveHundred.denomination * e.target.value,
                    });
                  }}
                  padding={""}
                  leftIcon={<></>}
                />{" "}
              </DenominationWrap>
              <DenominationWrap>
                <Text>200</Text>
                <DenominationInput
                  name={""}
                  type={""}
                  placeholder={""}
                  rightIcon={<></>}
                  label={""}
                  check={""}
                  value={twoHundred.quantity}
                  padding={""}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10) || 0;
                    const quantity = newQuantity.toString();
                    setTwoHundred({
                      denomination: 200,
                      quantity: quantity,
                      amount: twoHundred.denomination * e.target.value,
                    });
                  }}
                  leftIcon={<></>}
                />{" "}
              </DenominationWrap>
              <DenominationWrap>
                <Text>100</Text>
                <DenominationInput
                  name={""}
                  type={""}
                  placeholder={""}
                  rightIcon={<></>}
                  label={""}
                  check={""}
                  padding={""}
                  value={hundred.quantity}
                  leftIcon={<></>}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10) || 0;
                    const quantity = newQuantity.toString();
                    setHundred({
                      denomination: 100,
                      quantity: quantity,
                      amount: hundred.denomination * e.target.value,
                    });
                  }}
                />{" "}
              </DenominationWrap>
              <DenominationWrap>
                <Text>50</Text>
                <DenominationInput
                  name={""}
                  type={""}
                  placeholder={""}
                  rightIcon={<></>}
                  label={""}
                  value={fifty.quantity}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10) || 0;
                    const quantity = newQuantity.toString();
                    setFifty({
                      denomination: 50,
                      quantity: quantity,
                      amount: fifty.denomination * e.target.value,
                    });
                  }}
                  check={""}
                  padding={""}
                  leftIcon={<></>}
                />{" "}
              </DenominationWrap>
              <DenominationWrap>
                <Text>20</Text>
                <DenominationInput
                  name={""}
                  type={""}
                  placeholder={""}
                  rightIcon={<></>}
                  label={""}
                  value={twenty.quantity}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10) || 0;
                    const quantity = newQuantity.toString();
                    setTwenty({
                      denomination: 20,
                      quantity: quantity,
                      amount: twenty.denomination * e.target.value,
                    });
                  }}
                  check={""}
                  padding={""}
                  leftIcon={<></>}
                />{" "}
              </DenominationWrap>
              <DenominationWrap>
                <Text>10</Text>
                <DenominationInput
                  name={""}
                  type={""}
                  placeholder={""}
                  rightIcon={<></>}
                  label={""}
                  value={ten?.quantity}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10) || 0;
                    const quantity = newQuantity.toString();
                    setTen({
                      denomination: 10,
                      quantity: quantity,
                      amount: ten.denomination * e.target.value,
                    });
                  }}
                  check={""}
                  padding={""}
                  leftIcon={<></>}
                />{" "}
              </DenominationWrap>
              <DenominationWrap>
                <Text>5</Text>
                <DenominationInput
                  name={""}
                  type={""}
                  placeholder={""}
                  value={five.quantity}
                  onChange={(e) => {
                    const newQuantity = parseInt(e.target.value, 10) || 0;
                    const quantity = newQuantity.toString();
                    setFive({
                      denomination: 5,
                      quantity: quantity,
                      amount: five.denomination * e.target.value,
                    });
                  }}
                  rightIcon={<></>}
                  label={""}
                  check={""}
                  padding={""}
                  leftIcon={<></>}
                />{" "}
              </DenominationWrap>
            </DenominationContainer>

            <FieldWrapD>
              <DetailsWrapper>
                <LeftD>
                  <Text>Total Amount:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>
                    {currency} {totalAmount}.00
                  </Text>{" "}
                </RightD>
              </DetailsWrapper>
              <DetailsWrapper>
                <LeftD>
                  <Text>Amount in words:</Text>{" "}
                </LeftD>
                <RightD>
                  {/* <Text>{formatAmount(currency, totalAmount)}</Text>{" "} */}
                  <Text>
                    {convertAmountToWords(totalAmount)}{" "}
                    {currency === "NGN"
                      ? "Naira"
                      : currency === "GBP"
                      ? "Pound Sterling"
                      : "Dollar"}
                  </Text>{" "}
                </RightD>
              </DetailsWrapper>
              <DetailsWrapper>
                <LeftD>
                  <Text>Transaction Date:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{currentDate.toJSON().slice(0, 10)}</Text>{" "}
                </RightD>
              </DetailsWrapper>
              <DetailsWrapper>
                <LeftD>
                  <Text>Branch Code:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{branchcode}</Text>{" "}
                </RightD>
              </DetailsWrapper>
              <DetailsWrapper>
                <LeftD>
                  <Text>Paid By:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{bankUser}</Text>{" "}
                </RightD>
              </DetailsWrapper>
              <DetailsWrapper>
                <LeftD>
                  <Text>Cash Received By:</Text>{" "}
                </LeftD>
                <RightD>
                  <Text>{bankUser}</Text>{" "}
                </RightD>
              </DetailsWrapper>
            </FieldWrapD>
          </RightContainerDepo>
        </ContainerDepo>
      </ComponentWrapper>

      {isOpenTransModal && (
        <GeneralModal general>
          <KeyValueMapping
            onSubmit={postDepositDetails}
            handleClose={handleClose}
            postWithdrawalLoading={isLoading}
            data={transData}
          />
        </GeneralModal>
      )}

      {transactionStatus && (
        <SuccessModal
          message={transMessage}
          handleClose={handleClose}
          generateReceipt={generateReceipt}
          approval={transId !== ""}
        />
      )}

      {transactionReceipt && (
        <GeneralModal general>
          <Top>
            <TopTitle></TopTitle>
            <IconWrap>
              <CloseIcon onClick={handleCloseReceipt} />
            </IconWrap>
          </Top>

          {generatePDF && (
            <PDFGenerator
              componentToRender={
                <DReceiptView data={transReceipt} title="Cash Deposit" />
              }
            />
          )}
        </GeneralModal>
      )}

      {openMandate && imageData !== "" && (
        <>
          <GeneralModal general>
            <Top>
              <TopTitle>Mandate </TopTitle>
              <IconWrap>
                <CloseIcon onClick={handleCloseMandate} />
              </IconWrap>
            </Top>

            <>
              {" "}
              <img src={`data:image/jpeg;base64,${imageData}`} alt="ch" />
            </>
          </GeneralModal>
        </>
      )}
    </>
  );
};

export default CashDeposit;
